




























import {SimplyVideoAdminLabel, distributorLabel} from '../../router';
import AddEmails from '@/components/AddEmails.vue';
import InvitedEmailsTable from '@/components/tables/InvitedEmailsTable.vue'

import {Store} from '@/store/store'
import Component from 'vue-class-component'

@Component({
  components: {
    AddEmails,
    InvitedEmailsTable,
  },
  metaInfo: {
    title: 'Create Resellers',
  }
})
export default class CreateReseller extends Store {
  componentTitle = 'Create Resellers';
  knownEmails: any = [];
  fields: Array<any> = [
    {key: 'business_name', label: 'Name', tdClass: 'name w33'},
    {key: 'email', label: 'Email', tdClass: 'email'},
    {key: 'is_pending', label: 'Status', tdClass: 'status w10'},
    {key: 'manage', label: '', tdClass: 'link w10'},
  ];

  simplyVideoAdminLabel = SimplyVideoAdminLabel;
  DistributorLabel = distributorLabel;
  
  async mounted(){
    await this.refresh()    
  }

  async refresh(){
    this.knownEmails = await this.getListOfResellers()
  }

  /**
   * Sends an email reminder to a provided email
   * @arg {String} email
   * @vuese
   */
  sendActivationEmail(email: string[]) {
    // Send
    this.inviteResellers({
      listOfEmails: {
        //@ts-ignore
        emails: email
      }
    }).then(() => {
      this.$toast.success('New customer invites sent')
      this.refresh()
    }).catch(err => this.$toast.error('Failed To send invite '))
  }
}
